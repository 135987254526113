import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { JwtAuthService } from './auth/jwt-auth.service';
import { toCamelCase } from 'js-convert-case';
import { RouteProtectionService } from './route-protection.service';
import { ProfileType, UserRole } from '../enums/profile-type.enum';

export type MenuType =
  | 'admin-menu'
  | 'portal-user-menu'
  | 'business-user-menu'
  | 'driver-menu'
  | 'telehealth-pharmacy-menu'
  | 'doctor-user-menu'
  | 'nurse-practitioner-menu';
export interface OrderCount {
  online: online_counts;
  in_store: in_store_counts;
  all_orders: all_orders_counts;
  closed: closed_counts;
  telehealth: telehealth_counts;
}
export type OrderCountTypes = OrderCount[keyof OrderCount];

export interface online_counts {
  pending_approval: number;
  clinic_to_review: number;
  clinic_to_action: number;
  price_request: number;
  processing: number;
  delivery_pickup: number;
}

export interface in_store_counts {
  pending_approval: number;
  clinic_to_review: number;
  clinic_to_action: number;
  price_request: number;
  processing: number;
  delivery_pickup: number;
}

export interface all_orders_counts {
  clinic_to_review: number;
  clinic_to_action: number;
  request_issued: number;
  pending_approval: number;
  price_request: number;
  processing: number;
  delivery_pickup: number;
  active: number;
  exception: number;
}

export interface closed_counts {
  completed: number;
  cancelled: number;
}

export interface telehealth_counts {
  pending: number;
  script_issued: number;
  completed: number;
  cancelled: number;
}

export interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  key: string; //Unique key to determine value
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  value?: string;
  color1?: string;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  value?: string;
  color1?: string;
  id?: string;
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  adminMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      key: 'create-order',
      type: 'link',
      icon: 'description',
      state: 'orders/create-order',
    },
    {
      name: 'Orders',
      key: 'online-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: 'warn',
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: '#72cb38' },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: '#ff7901' },
        { name: 'Price Request', state: 'price_request', value: '', color1: '#1e98d5' },
        { name: 'Ready to Process', state: 'processing', value: '', color1: '#1e98d5' },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: '#1e98d5' },
      ],
    },
    {
      name: 'Telehealth',
      key: 'telehealth',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'telehealth',
      value: '',
      sub: [
        {
          name: 'Telehealth Request',
          type: 'link',
          state: 'telehealth-request',
        },
        {
          name: 'Pending',
          type: 'link',
          state: 'pending',
          value: '',
          color1: '#ff7901',
        },
        {
          name: 'Script Issued',
          type: 'link',
          state: 'script-issued',
          value: '',
          color1: '#1e98d5',
        },
      ],
    },
    // {
    //   name: 'In Store',
    //   key: 'in-store-orders',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   svgIcon: 'ulb_server',
    //   state: 'orders/in_store',
    //   value: '',
    //   color1: "warn",
    //   sub: [
    //     // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
    //     { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
    //     { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
    //     { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
    //     { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
    //   ]
    // },
    {
      name: 'All Orders',
      key: 'all-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/admin',
      value: '',
      color1: 'warn',
      sub: [
        { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: '#72cb38' },
        { name: 'Clinic to Update', state: 'clinic_to_update', value: '', color1: '#1e98d5' },
        { name: 'Waiting Room', state: 'waiting_room', value: '', color1: '#1e98d5' },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: '#72cb38' },
        {
          name: 'Waiting for Info',
          state: 'waiting_for_information',
          value: '',
          color1: '#72cb38',
        },
        { name: 'Request Issued', state: 'request_issued', value: '', color1: '#1e98d5' },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: '#ff7901' },
        { name: 'Price Request', state: 'price_request', value: '', color1: '#1e98d5' },
        { name: 'Ready to Process', state: 'processing', value: '', color1: '#1e98d5' },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: '#1e98d5' },
        { name: 'Active', state: 'active', value: '', color1: '#ff3d57' },
        { name: 'Exception', state: 'exception', value: '', color1: 'pink' },
        { name: 'Pending Batch Processing', state: 'pending_batch_processing', value: '', color1: '#ff7901' },
      ],
    },
    {
      name: 'Closed',
      key: 'closed-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: 'warn',
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: 'grey' },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: 'grey' },
      ],
    },
    {
      name: 'Users',
      key: 'users',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'users',
      value: '',
      sub: [
        {
          name: 'Invite',
          type: 'link',
          icon: 'person_add',
          state: 'invite',
        },
        {
          name: 'Create User',
          type: 'link',
          icon: 'person_add',
          state: 'create-user',
        },
        {
          name: 'Manage User',
          type: 'link',
          icon: 'person_add',
          state: 'manage-user',
        },
      ],
    },
    {
      name: 'Resources',
      key: 'resources',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'On Boarding',
          type: 'link',
          icon: 'ulb_server',
          state: 'on-boarding',
        },
        {
          name: 'Setup',
          type: 'link',
          icon: 'ulb_server',
          state: 'setup',
        },
        {
          name: 'Training',
          type: 'link',
          icon: 'ulb_server',
          state: 'training-guide',
        },
        {
          name: 'Program',
          type: 'link',
          icon: 'person_add',
          state: 'pharmacy-program'
        },
        {
          name: 'Telehealth User Guide',
          type: 'link',
          icon: 'ulb_server',
          state: 'telehealth-user-guide',
        },
      ],
    },
    {
      name: 'Reports',
      key: 'reports',
      type: 'dropDown',
      icon: 'description',
      state: 'reports',
      sub: [
        { name: 'Dashboard', state: 'dashboard' },
        { name: 'Location', state: 'locations' },
        { name: 'Partners', state: 'partners' },
        { name: 'Telehealth', state: 'telehealth' },
        { name: 'Programs', state: 'programs' },
      ],
    },
    {
      name: 'Stores',
      key: 'stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [
        { name: 'New Store', state: 'create-store' },
        { name: 'Store Details', state: 'store-detail' },
        { name: 'Store Listing', state: 'listing' },
        // { name: 'Delivery Options', state: 'analytics' },
        // { name: 'Delivery Partners', state: 'deliverypartners' },
      ],
    },
    {
      name: 'Clinician',
      key: 'clinician',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'clinician',
      sub: [
        { name: 'Clinician', state: 'profiles' },
        { name: 'Adhoc Shifts', state: 'adhoc-shifts' },
      ],
    },
    {
      name: 'Products',
      key: 'products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name: 'Master Catalogue', state: 'master-catalogue' },
        { name: 'Catalogue', state: 'catalogue' },
        { name: 'Promotions', state: 'promotions' },
        // { name: 'Telehealth', state: 'telehealth' }
        // { name: 'New Product', state: 'default' }
      ],
    },
    {
      name: 'Rendr',
      key: 'rendr',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_rendr',
      state: 'orders/rendr',
    },
    {
      name: 'Billing',
      key: 'billing',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'dashboard',
      sub: [{ name: 'Statements', state: 'default' }],
    },
    {
      name: 'Admin',
      key: 'admin',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'admin',
      sub: [
        { name: 'Known As', state: 'default' },
        { name: 'Notification Monitor', state: 'monitor' },
        { name: 'Restricted Meds', state: 'restricted-meds' },
        { name: 'Manage Banner', state: 'manage-banner' },
        { name: 'Telehealth Config', state: 'telehealth-configurations' },
        // { name: 'Programs', state: 'programs'},
      ],
    },
    {
      name: 'Locumate',
      key: 'locumate',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'locumate_logo',
      state: 'locumate',
    },
    {
      name: 'Fluger',
      key: 'fluger',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'fluger_logo',
      state: 'fluger',
    },
  ];

  portalUserMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      type: 'link',
      key: 'create-order',
      icon: 'description',
      state: 'orders/create-order',
    },
    {
      name: 'Orders',
      key: 'online-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: 'warn',
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: '#ff7901' },
        { name: 'Price Request', state: 'price_request', value: '', color1: '#1e98d5' },
        { name: 'Ready to Process', state: 'processing', value: '', color1: '#1e98d5' },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: '#1e98d5' },
      ],
    },
    {
      name: 'Telehealth',
      key: 'telehealth',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'telehealth',
      value: '',
      sub: [
        {
          name: 'Telehealth Request',
          type: 'link',
          state: 'telehealth-request',
        },
        {
          name: 'Pending',
          type: 'link',
          state: 'pending',
          value: '',
          color1: '#ff7901',
        },
        {
          name: 'Script Issued',
          type: 'link',
          state: 'script-issued',
          value: '',
          color1: '#1e98d5',
        },
      ],
    },
    // {
    //   name: 'In Store',
    //   key: 'in-store-orders',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   svgIcon: 'ulb_server',
    //   state: 'orders/in_store',
    //   value: '',
    //   color1: "warn",
    //   sub: [
    //     { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
    //     { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
    //     { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
    //     { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
    //   ]
    // },
    {
      name: 'Closed',
      key: 'closed-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: 'warn',
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: 'grey' },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: 'grey' },
      ],
    },
    {
      name: 'Resources',
      key: 'resources',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'On Boarding',
          type: 'link',
          icon: 'ulb_server',
          state: 'on-boarding',
        },
        {
          name: 'Setup',
          type: 'link',
          icon: 'ulb_server',
          state: 'setup',
        },
        {
          name: 'Training',
          type: 'link',
          icon: 'ulb_server',
          state: 'training-guide',
        },
        {
          name: 'Program',
          type: 'link',
          icon: 'person_add',
          state: 'pharmacy-program'
        },
        {
          name: 'Telehealth User Guide',
          type: 'link',
          icon: 'ulb_server',
          state: 'telehealth-user-guide',
        },
      ],
    },
    // {
    //   name: "Users",
    //   key: 'users',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   //TODO: change icon
    //   svgIcon: 'ulb_server',
    //   state: 'users',
    //   value: '',
    //   sub: [
    //   ]
    // },

    {
      name: 'Reports',
      key: 'reports',
      type: 'dropDown',
      icon: 'description',
      state: 'reports',
      sub: [{ name: 'Location', state: 'locations' }],
    },
    {
      name: 'Stores',
      key: 'stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [{ name: 'Store Details', state: 'store-detail' }],
    },
    {
      name: 'Products',
      key: 'products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name: 'Catalogue', state: 'catalogue' },
        // { name: 'New Product', state: 'default' }
      ],
    },
    {
      name: 'Locumate',
      key: 'locumate',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'locumate_logo',
      state: 'locumate',
    },
    {
      name: 'Fluger',
      key: 'fluger',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'fluger_logo',
      state: 'fluger',
    },
  ];

  doctorUserMenu: IMenuItem[] = [
    {
      name: 'All Orders',
      key: 'all-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: 'warn',
      sub: [
        { name: 'Clinic to Update', state: 'clinic_to_update', value: '', color1: '#1e98d5' },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: '#72cb38' },
        { name: 'Request Issued', state: 'request_issued', value: '', color1: '#1e98d5' },
      ],
    },
    {
      name: 'Closed',
      key: 'closed-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: 'warn',
      sub: [{ name: 'Completed', state: 'completed', value: '', color1: 'grey' }],
    },
  ];

  businessUserMenu: IMenuItem[] = [
    // {
    //   name: 'Create New Order',
    //   type: 'link',
    //   key: 'create-order',
    //   icon: 'description',
    //   state: 'orders/create-order',
    // },
    {
      name: 'Orders',
      key: 'online-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: 'warn',
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: '#ff7901' },
        { name: 'Price Request', state: 'price_request', value: '', color1: '#1e98d5' },
        { name: 'Ready to Process', state: 'processing', value: '', color1: '#1e98d5' },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: '#1e98d5' },
      ],
    },
    {
      name: 'Telehealth',
      key: 'telehealth',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'telehealth',
      value: '',
      sub: [
        {
          name: 'Telehealth Request',
          type: 'link',
          state: 'telehealth-request',
        },
        {
          name: 'Pending',
          type: 'link',
          state: 'pending',
          value: '',
          color1: '#ff7901',
        },
        {
          name: 'Script Issued',
          type: 'link',
          state: 'script-issued',
          value: '',
          color1: '#1e98d5',
        },
      ],
    },
    // {
    //   name: 'In Store',
    //   key: 'in-store-orders',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   svgIcon: 'ulb_server',
    //   state: 'orders/in_store',
    //   value: '',
    //   color1: "warn",
    //   sub: [
    //     { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
    //     { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
    //     { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
    //     { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
    //   ]
    // },
    {
      name: 'Closed',
      key: 'closed-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: 'warn',
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: 'grey' },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: 'grey' },
      ],
    },
    // {
    //   name: "Users",
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   key: 'users',
    //   //TODO: change icon
    //   svgIcon: 'ulb_server',
    //   state: 'users',
    //   value: '',
    //   sub: [
    //     // {
    //     //   name: 'Invite',
    //     //   type: 'link',
    //     //   icon: 'person_add',
    //     //   state: 'invite'
    //     // }
    //   ]
    // },
    {
      name: 'Resources',
      key: 'resources',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'On Boarding',
          type: 'link',
          icon: 'ulb_server',
          state: 'on-boarding',
        },
        {
          name: 'Setup',
          type: 'link',
          icon: 'ulb_server',
          state: 'setup',
        },
        {
          name: 'Training',
          type: 'link',
          icon: 'ulb_server',
          state: 'training-guide',
        },
        {
          name: 'Program',
          type: 'link',
          icon: 'person_add',
          state: 'pharmacy-program'
        },
        {
          name: 'Telehealth User Guide',
          type: 'link',
          icon: 'ulb_server',
          state: 'telehealth-user-guide',
        },
      ],
    },
    {
      name: 'Reports',
      key: 'reports',
      type: 'dropDown',
      icon: 'description',
      state: 'reports',
      sub: [
        { name: 'Location', state: 'locations' },
        { name: 'Programs', state: 'programs' },
      ],
    },
    {
      name: 'Stores',
      key: 'stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [
        { name: 'New Store', state: 'create-store' },
        { name: 'Store Details', state: 'store-detail' },
        { name: 'Store Listing', state: 'listing' },
      ],
    },
    {
      name: 'Products',
      key: 'products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name : 'Master Catalogue', state: 'master-catalogue' },
        { name: 'Catalogue', state: 'catalogue' },
        { name: 'Promotions', state: 'promotions' },
        // { name: 'New Product', state: 'default' }
      ],
    },
  ];

  driverMenu: IMenuItem[] = [
    {
      name: 'Orders',
      key: 'online-orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders',
      value: '',
      color1: 'warn',
      sub: [
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: '#1e98d5' },
      ],
    },
  ];

  telehealthPharmacyMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      type: 'link',
      key: 'create-order',
      icon: 'description',
      state: 'orders/create-order',
    },
    {
      name: 'Telehealth',
      key: 'telehealth',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'telehealth',
      value: '',
      sub: [
        {
          name: 'Telehealth Request',
          type: 'link',
          state: 'telehealth-request',
        },
        {
          name: 'Pending',
          type: 'link',
          state: 'pending',
          value: '',
          color1: '#ff7901',
        },
        {
          name: 'Script Issued',
          type: 'link',
          state: 'script-issued',
          value: '',
          color1: '#1e98d5',
        },
        { name: 'Completed', state: 'completed', value: '', color1: 'grey' },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: 'grey' },
      ],
    },
    {
      name: 'Resources',
      key: 'resources',
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'Telehealth User Guide',
          type: 'link',
          icon: 'ulb_server',
          state: 'telehealth-user-guide',
        },
      ],
    },
    {
      name: 'Stores',
      key: 'stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [{ name: 'Store Details', state: 'store-detail' }],
    },
  ];

  newOrderCount: OrderCount;

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets portalUserMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.portalUserMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor(
    private http: HttpClient,
    private jwtAuth: JwtAuthService,
    private routeProtectionService: RouteProtectionService
  ) { }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: MenuType) {
    try {
      let menu: IMenuItem[] = this[toCamelCase(menuType)];

      //configure menu by iterating through hidden keys object. Exclude any menu items if key exists
      menu = this.routeProtectionService.filterMenu(menu);

      this.menuItems.next(menu);
    } catch (error) {
      throw new Error('failed to load menu: ' + error);
    }
  }

  publishNavigation(role: UserRole) {
    switch (role) {
      case ProfileType.Admin:
        this.publishNavigationChange('admin-menu');
        break;
      case ProfileType.PortalUser:
      case ProfileType.StandardPharmacy:
        this.publishNavigationChange('portal-user-menu');
        break;
      case ProfileType.TelehealthPharmacy:
        this.publishNavigationChange('telehealth-pharmacy-menu');
        break;
      case ProfileType.BusinessUser:
        this.publishNavigationChange('business-user-menu');
        break;
      case ProfileType.Driver:
        this.publishNavigationChange('driver-menu');
        break;
      case ProfileType.Doctor:
        this.publishNavigationChange('doctor-user-menu');
        break;
      case ProfileType.NursePractitioner:
        this.publishNavigationChange('nurse-practitioner-menu');
        break;
      default:
        break;
    }
  }

  set_counts(menu_item: IMenuItem, counts: OrderCountTypes) {
    menu_item?.sub?.forEach(element => {
      element.value = counts[element.state.replace('-', '_')];
    });
  }

  setOrderCount(ordersCount: OrderCount) {
    this.newOrderCount = ordersCount;

    const menu: IMenuItem[] = this.menuItems.value;

    const online_menu = menu.find(m => m.name === 'Orders');
    const closed_menu = menu.find(m => m.name === 'Closed');
    const telehealth_menu = menu.find(m => m.name === 'Telehealth');
    const all_orders_menu = menu.find(m => m.name === 'All Orders');

    if (online_menu !== null) {
      this.set_counts(online_menu, this.newOrderCount.online);
    }
    if (all_orders_menu !== null) {
      this.set_counts(all_orders_menu, this.newOrderCount.all_orders);
    }
    if (closed_menu !== null) {
      this.set_counts(closed_menu, this.newOrderCount.closed);
    }
    if (telehealth_menu !== null) {
      this.set_counts(telehealth_menu, this.newOrderCount.telehealth);
    }
    this.menuItems.next([...menu]);
  }

  getOrderCountForMenu() {
    const role = this.jwtAuth.getUser().role;
    let searchParams = new HttpParams();

    switch (role) {
      case ProfileType.Admin:
        searchParams = searchParams.append('type', 'online,all_orders,closed,telehealth');
        break;
      case ProfileType.PortalUser:
      case ProfileType.StandardPharmacy:
      case ProfileType.BusinessUser:
        searchParams = searchParams.append('type', 'online,closed,telehealth');
        break;
      case ProfileType.TelehealthPharmacy:
        searchParams = searchParams.append('type', 'telehealth');
        break;

      case ProfileType.Doctor:
      case ProfileType.NursePractitioner:
        searchParams = searchParams.append('type', 'all_orders,closed');
        break;
      case ProfileType.Driver:
        return;
      default:
        return;
    }

    this.http
      .get<OrderCount>(environment.apiURL1 + 'get_order_count_for_menu_v1', {
        params: searchParams,
      })
      .subscribe(res => {
        this.setOrderCount(res);
      });
  }
}
