import { HttpParams } from '@angular/common/http';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { OrderDetail, OrderStatus } from '../services/data-order.service';
import { OrderStatusEnum } from './status-options.helper';
import { OrderData } from 'app/views/orders/orders-table/orders-table.component';

export function getIndexBy(array: Array<{}>, { name, value }): number {
  for (let i = 0; i < array.length; i++) {
    if (array[i][name] === value) {
      return i;
    }
  }
  return -1;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(selector) {
  var elm = document.querySelector(selector);
  if (!selector || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    window.scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            window.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          window.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export const getValueFromKey = <T extends object>(object: T, key: string) => {
  return key.split('.').reduce((obj, str) => {
    return obj?.[str] ?? null;
  }, object);
};

export const removeEmptyProperties = (obj: any): void => {
  Object.keys(obj).forEach(
    key => (obj[key] === '' || obj[key] === undefined || obj[key] === null) && delete obj[key]
  );
};

export const createHttpParams = (payload: any): HttpParams => {
  let params = new HttpParams();
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      params = params.append(key, payload[key]);
    }
  }
  return params;
};
export interface FormDataPayload {
  [key: string]: string | number | Blob | File | Array<string | Blob | File> | FormDataPayload;
}

export const toFlag = (input: boolean | null | undefined) => {
  return input ? 1 : 0;
};
export const toBinaryFlag = (input: boolean | null | undefined) => {
  return input ? 1 : 0;
};

export const wait = async (seconds = 0) => {
  await new Promise(resolve => {
    setTimeout(() => {
      resolve(null);
    }, seconds);
  });
};

export const convertPayloadToFormData = (payload: FormDataPayload): FormData => {
  const formData = new FormData();

  const isBlobOrFile = (value: unknown): value is Blob | File => {
    return value instanceof Blob || value instanceof File;
  };

  const appendFormData = (data: FormDataPayload, keyPrefix: string = ''): void => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const formKey = keyPrefix ? `${keyPrefix}[${key}]` : key;

        if (Array.isArray(value)) {
          value.forEach((element, index) => {
            const arrayKey = `${formKey}[${index}]`;
            if (typeof element === 'string' || isBlobOrFile(element)) {
              formData.append(arrayKey, element);
            } else if (typeof element === 'object' && element !== null) {
              formData.append(arrayKey, JSON.stringify(element));
            }
          });
        } else if (
          typeof value === 'string' ||
          value instanceof Blob ||
          value instanceof File
        ) {
          formData.append(formKey, value);
        } else if (typeof value === 'object' && value !== null) {
          formData.append(key, JSON.stringify(value));
        } else if (typeof value === 'number') {
          formData.append(key, value.toString())
        }
      }
    }
  };

  appendFormData(payload);
  return formData;
};

export const unescapeNewlineChars = (text: string): string => {
  if (text === null || text === undefined) {
    return '';
  }
  return text.replace(/\\n/g, '\n');
};
export interface ErrorMessage {
  error?: { [key: string]: string[] };
  msg?: string;
}

export const getFirstErrorMessage = (errorResponse: ErrorMessage): string => {
  if (errorResponse.msg) {
    return errorResponse.msg;
  }

  if (errorResponse.error) {
    const firstErrorKey = Object.keys(errorResponse.error)[0];
    return errorResponse.error[firstErrorKey]?.[0] || 'An unknown error occurred.';
  }

  return 'An unknown error occurred.';
};

export const shouldCreateFollowUpAppointment = (orderStatus: OrderStatus, orderData: OrderDetail['orderData'] | OrderData): boolean => {
  const sourceKey = orderData.hasOwnProperty('source') ? 'source' : 'Source';
  return (
    orderStatus === OrderStatusEnum.Completed &&
    orderData.order_class !== 'Medmate' &&
    !orderData[sourceKey].endsWith('Follow-Up')
  );
};

export const capitalizeEachWord = (str: string): string => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const normalizeString = (value: string | undefined | null): string => {
  return value?.toLowerCase().replace(/[^a-z0-9]/g, '') || '';
}

export const isOrderTypePickUp = (ordertype: string | undefined | null): boolean => {
  return normalizeString(ordertype) === 'pickup';
}